<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                        <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-overlay :show="unitLoad">
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <table class="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.fiscal_year')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.fiscal_year : item.fiscal_year_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.mill_name')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.mill_name : item.mill_name_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.subzone_office_name')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.subzone_name : item.subzone_name_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.center_name')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.center_name : item.center_name_bn }} </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{{$t('bsri_demandDIS.unit_no')}}</th>
                                                                <td> {{ $i18n.locale === 'en' ? item.unit_no : item.unit_no_bn }} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                                                    <b-col xl="12" lg="12" sm="12">
                                                        <b-table-simple bordered>
                                                            <thead class="text-white bg-success">
                                                                <tr>
                                                                    <th class="text-center mytd" >{{ $t('globalTrans.sl_no') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.farmer_name') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.father_name')}}</th>
                                                                    <!-- <th class="mytd" scope="col">{{ $t('bsri_demandDIS.farmer_village') }}</th> -->
                                                                    <th class="mytd" scope="col">{{ $t('common_config.far_wallet_no') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.verified_land') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('bsri_demandDIS.amount_money') }}</th>
                                                                    <th class="mytd" scope="col">{{ $t('globalTrans.action') }}</th>
                                                                </tr>
                                                            </thead>
                                                            <b-tbody>
                                                                <template v-for="(infoo, indexx) in formData.demandDetails">
                                                                    <template style="display:inline" :id="indexx"> {{ $i18n.locale === 'bn' ? infoo.cultivation_name_bn : infoo.cultivation_name }} </template>
                                                                    <b-tr v-for="(info, index) in infoo.farmer_list" :key="index">
                                                                        <b-td class="text-center mytd">{{$n(index + 1)}}</b-td>
                                                                        <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.name_bn : info.name }}</b-td>
                                                                        <b-td class="mytd">{{ $i18n.locale === 'bn' ? info.father_name_bn : info.father_name }}</b-td>
                                                                        <b-td class="mytd">{{ ($i18n.locale == 'bn' ? '০': '0') + $n(info.wallet_no, { useGrouping: false }) }}</b-td>
                                                                        <b-td class="mytd">{{ $n(info.total_land) }}</b-td>
                                                                        <b-td class="mytd">
                                                                            <ValidationProvider name="total_land" vid="verified_land_quantity" rules="required">
                                                                                <b-form-group
                                                                                    class="row"
                                                                                    label-cols-sm="3"
                                                                                    label-for="verified_land_quantity"
                                                                                    slot-scope="{ valid, errors }"
                                                                                >
                                                                                <b-form-input
                                                                                    plain
                                                                                    v-model="info.verified_land_quantity"
                                                                                    id="verified_land_quantity"
                                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                                >
                                                                                </b-form-input>
                                                                                <div class="invalid-feedback">
                                                                                {{ errors[0] }}
                                                                                </div>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-td>
                                                                        <b-td class="mytd">{{ $n(amount(infoo.cultivation_id, info.verified_land_quantity), { useGrouping: false }) }}</b-td>
                                                                        <b-td class="mytd">
                                                                            <ValidationProvider name="Designation" vid="designation_id">
                                                                                <b-form-group
                                                                                    class="row"
                                                                                    label-cols-sm="3"
                                                                                    label-for="designation_id"
                                                                                >
                                                                                  <b-form-checkbox
                                                                                    class="mt-2"
                                                                                    v-model="info.selected"
                                                                                    :value="1"
                                                                                    :unchecked-value="2"
                                                                                >
                                                                                </b-form-checkbox>
                                                                                </b-form-group>
                                                                            </ValidationProvider>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </template>
                                                                <b-tr v-if="nodata">
                                                                    <td colspan="5" class="text-center">
                                                                        <span class="text-black">{{ $t('globalTrans.noDataFound') }}</span>
                                                                    </td>
                                                                </b-tr>
                                                            </b-tbody>
                                                        </b-table-simple>
                                                    </b-col>
                                                </b-row>
                                                <div class="row">
                                                    <div class="col-sm-3"></div>
                                                    <div class="col text-right">
                                                        <b-button type='button' variant="primary" @click="forwardPageShow = !forwardPageShow" class="mr-2">{{ $t('dae_grant_allocation_distribution.forward')}}</b-button>
                                                        &nbsp;
                                                        <b-button type="submit" variant="primary" class="mr-2">{{ approveBtnName }}</b-button>
                                                        &nbsp;
                                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="mt-4" v-if="forwardPageShow">
                                                    <b-row>
                                                        <b-col lg="3 offset-2" sm="5">
                                                            <ValidationProvider name="office_type" vid="office_type_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    label-for="office_type_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <template v-slot:label>
                                                                    {{ $t('bsri_demandDIS.office_type') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.office_type_id"
                                                                    :options="officeTypeList"
                                                                    id="office_type_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col lg="3 offset-2" sm="5">
                                                            <ValidationProvider name="office" vid="office_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    label-for="office_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <template v-slot:label>
                                                                    {{ $t('bsri_demandDIS.office') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.office_id"
                                                                    :options="officeList"
                                                                    id="office_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col lg="3 offset-2" sm="5">
                                                            <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    label-for="designation_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <template v-slot:label>
                                                                    {{ $t('bsri_demandDIS.designation') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.designation_id"
                                                                    :options="designationList"
                                                                    id="designation_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col lg="3 offset-2" sm="5">
                                                            <ValidationProvider name="Employee" vid="receiver_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    label-for="receiver_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <template v-slot:label>
                                                                    {{ $t('bsri_demandDIS.officer') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.receiver_id"
                                                                    :options="officerList"
                                                                    id="receiver_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{ officerLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col lg="8 offset-2" sm="10">
                                                            <ValidationProvider name="Comment" vid="comment" rules="required|max:200">
                                                                <b-form-group
                                                                    label-for="comment"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                    <template v-slot:label>
                                                                    {{ $t('budget.comment') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-textarea
                                                                        plain
                                                                        v-model="formData.comment"
                                                                        id="comment"
                                                                        placeholder="Maximum 200 word.."
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    >
                                                                    </b-form-textarea>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="row">
                                                        <div class="col-sm-3"></div>
                                                        <div class="col text-right">
                                                            <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save')}}</b-button>
                                                            &nbsp;
                                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-overlay>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { subFarApprovalStore, subFarApprovalDraftStore, subFarDemandDetails, designationWiseUser } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            forwardPageShow: false,
            loading: false,
            nodata: false,
            unitLoad: false,
            draftBtnName: this.$t('globalTrans.draft'),
            approveBtnName: this.$t('globalTrans.approve'),
            updateBtnName: this.$t('globalTrans.update'),
            formData: {
                office_type_id: 0,
                office_id: 0,
                fiscal_year_id: 0,
                mill_info_id: 0,
                subzone_office_id: 0,
                center_id: 0,
                unit_id: 0,
                comment: '',
                comment_bn: '',
                list: [],
                demandDetails: [],
                designation_id: 0,
                sender_id: this.$store.state.Auth.authUser.user_id,
                receiver_id: 0
            },
            demandDetails: [],
            officeList: [],
            designationList: [],
            officerList: []
        }
    },
    created () {
        if (this.item) {
            this.formData.fiscal_year = this.item.fiscal_year
            this.formData.fiscal_year_id = this.item.fiscal_year_id
            this.formData.mill_info_id = this.item.mill_info_id
            this.formData.subzone_office_id = this.item.subzone_office_id
            this.formData.center_id = this.item.center_id
            this.formData.unit_id = this.item.unit_id
            this.formData.crop_id = 1
            this.loadData(this.item)
        }
    },
    watch: {
        'formData.office_type_id': function (newValue) {
            this.getOfficeList(newValue)
        },
        'formData.office_id': function (newValue) {
            this.getDesignationList(newValue)
        },
        'formData.designation_id': function (newValue) {
            this.getOfficerList(newValue)
        }
     },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        officeTypeList: function () {
            return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === 8)
        }
    },
    methods: {
        amount (cultivationID, landQuantity) {
            const policyList = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.find(item => item.method_cultivation_id === cultivationID)
            const amount = landQuantity * parseInt(policyList.per_acr_amount)
            return amount
        },
        checked (key, info) {
            if (info.selected === 1) {
                this.formData.list.push(info)
            }
            if (info.selected === 2) {
                this.formData.splice(key, 1)
            }
        },
        async saveData () {
            this.unitLoad = true
            // this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            if (this.forwardPageShow === false) {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subFarApprovalDraftStore, this.formData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, subFarApprovalStore, this.formData)
            }
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.unitLoad = false
                this.$bvModal.hide('modal-5')
            } else {
                this.unitLoad = false
                this.$refs.form.setErrors(result.errors)
            }
        },
        loadData (item) {
            this.loading = true
            const params = Object.assign({}, this.item)
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.postData(incentiveGrantServiceBaseUrl, subFarDemandDetails, params).then(response => {
                if (response.success) {
                this.formData.demandDetails = this.getRelatinalData(response.data)
                } else {
                this.$store.dispatch('setList', [])
                }
                this.loading = false
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelatinalData (data) {
            const fiscalYearList = this.$store.state.commonObj.fiscalYearList
            const cultivationMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList
            const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList
            const subzoneOfficeList = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList
            const centerList = this.$store.state.incentiveGrant.commonObj.centerList
            const unitList = this.$store.state.incentiveGrant.commonObj.unitList
            const listData = data.map(item => {
                const fiscalYearListObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
                const millDataObject = millInfoList.find(mill => mill.value === item.mill_info_id)
                const cultivationMethodListDataObject = cultivationMethodList.find(methodItem => methodItem.value === item.cultivation_id)
                const subzoneObject = subzoneOfficeList.find(zone => zone.value === item.subzone_office_id)
                const centerObject = centerList.find(center => center.value === item.center_id)
                const unitObject = unitList.find(unit => unit.value === item.unit_id)
                const ojbData = {
                    fiscal_year: fiscalYearListObject !== undefined ? fiscalYearListObject.text_en : '',
                    fiscal_year_bn: fiscalYearListObject !== undefined ? fiscalYearListObject.text_bn : '',
                    mill_name: millDataObject !== undefined ? millDataObject.text_en : '',
                    mill_name_bn: millDataObject !== undefined ? millDataObject.text_bn : '',
                    cultivation_name: cultivationMethodListDataObject !== undefined ? cultivationMethodListDataObject.text_en : '',
                    cultivation_name_bn: cultivationMethodListDataObject !== undefined ? cultivationMethodListDataObject.text_bn : '',
                    subzone_name: subzoneObject !== undefined ? subzoneObject.text_en : '',
                    subzone_name_bn: subzoneObject !== undefined ? subzoneObject.text_bn : '',
                    center_name: centerObject !== undefined ? centerObject.text_en : '',
                    center_name_bn: centerObject !== undefined ? centerObject.text_bn : '',
                    unit_no: unitObject !== undefined ? unitObject.text_en : '',
                    unit_no_bn: unitObject !== undefined ? unitObject.text_en : ''
                }
                return Object.assign({}, item, ojbData)
            })
            return listData
        },
        getOfficeList (officeTypeId) {
            this.officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === officeTypeId).map(el => {
                return Object.assign({ value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
            })
        },
        getDesignationList (officeId) {
            this.designationList = this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.office_type_id === this.formData.office_type_id && item.office_id === officeId).map(el => {
                return Object.assign({ value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
            })
        },
        async getOfficerList (designationId) {
            this.officerLoading = true
            await RestApi.getData(incentiveGrantServiceBaseUrl, `${designationWiseUser}/${designationId}`)
            .then(response => {
                if (response.success) {
                    const data = response.data.data
                    this.officerList = data.map(item => {
                        return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
                    })
                } else {
                    this.officerList = []
                }
                this.officerLoading = false
            })
        }
    }
}
</script>
