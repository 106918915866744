
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_demandDIS.approval_subsidized_farmer') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_demandDIS.fiscal_year')"
                          label-for="fiscal_year_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              text="text"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_demandDIS.mill_name')"
                          label-for="mill_info_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.mill_info_id"
                              text="text"
                              :options="millInfoList"
                              id="mill_info_id"
                              :disabled="millDisabled"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_demandDIS.subzone_office_name')"
                          label-for="subzone_name"
                          >
                          <b-form-select
                              plain
                              v-model="search.subzone_office_id"
                              text="text"
                              :options="subzoneOfficeList"
                              id="subzone_name"
                              :disabled="subzoneDisabled"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_demandDIS.center_name')"
                          label-for="center_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.center_id"
                              text="text"
                              :options="centerList"
                              id="center_id"
                              :disabled="centerDisabled"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('bsri_demandDIS.unit_no')"
                          label-for="unit_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.unit_id"
                              text="text"
                              :options="unitList"
                              id="unit_id"
                              :disabled="unitDisabled"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>

            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.approval_subsidized_farmer') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                         <template v-slot:cell(tot_farmer)="data">
                                            {{ $n(data.item.tot_farmer, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-5 title="View Complain" @click="details(data.item)">
                                               <i class="fas fa-eye"></i>
                                            </a>
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                            <!-- <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button> -->
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="xl" :title="$t('bsri_demandDIS.subsidized_farmer')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <!-- <Details :item="item" :key="item"></Details> -->
             <Details :item="item" :key="item.id"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subFarApprovalPendingList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
      Details
  },
  data () {
    return {
        editItemId: 0,
        search: {
            fiscal_year_id: 0,
            mill_info_id: 0,
            subzone_office_id: 0,
            center_id: 0,
            unit_id: 0
        },
        millDisabled: true,
        subzoneDisabled: true,
        centerDisabled: true,
        unitDisabled: true,
        rows: [],
        subzoneOfficeList: [],
        centerList: [],
        unitList: [],
        item: ''
    }
  },
  mounted () {
  },
  computed: {
    fiscalYearList: function () {
        const objectData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    millInfoList: function () {
        const objectData = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    cultivationMethodList: function () {
        const cultMethodList = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.filter(item => item.status === 1)
        return cultMethodList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.fiscal_year'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.mill_name'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.subzone_office_name'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.center_name'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.unit_no'), class: 'text-center' },
          { label: this.$t('bsri_demandDIS.total_farmers'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'mill_name_bn' },
          { key: 'subzone_name_bn' },
          { key: 'center_name_bn' },
          { key: 'unit_no_bn' },
          { key: 'tot_farmer' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'mill_name' },
          { key: 'subzone_name' },
          { key: 'center_name' },
          { key: 'unit_no' },
          { key: 'tot_farmer' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
      'search.mill_info_id': function (newVal) {
          this.subzoneOfficeList = this.getSubzoneList(newVal)
          this.centerList = []
          this.unitList = []
      },
      'search.subzone_office_id': function (newVal) {
          this.centerList = this.getCenterList(newVal)
      },
      'search.center_id': function (newVal) {
          this.unitList = this.getUnitList(newVal)
      }
  },
  created () {
    this.setSearchParametter()
    this.loadData()
  },
  methods: {
    setSearchParametter () {
        const officeId = this.$store.state.Auth.authUser.office_id
        const officeTypeId = this.$store.state.Auth.authUser.office_type_id
        const millData = this.$store.state.incentiveGrant.commonObj.millInfoList.find(item => item.status === 1 && item.office_id === officeId && item.office_type_id === officeTypeId)
        const subzoneData = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.find(item => item.status === 1 && item.office_id === officeId && item.office_type_id === officeTypeId)
        const centerData = this.$store.state.incentiveGrant.commonObj.centerList.find(item => item.status === 1 && item.office_id === officeId && item.office_type_id === officeTypeId)
        const unitData = this.$store.state.incentiveGrant.commonObj.unitList.find(item => item.status === 1 && item.office_id === officeId && item.office_type_id === officeTypeId)
        if (millData) {
            this.search.mill_info_id = millData.value
            this.millDisabled = true
            this.subzoneDisabled = false
            this.centerDisabled = false
            this.unitDisabled = false
        }
        if (subzoneData) {
            this.search.mill_info_id = subzoneData.mill_info_id
            this.search.subzone_office_id = subzoneData.value
            this.millDisabled = true
            this.subzoneDisabled = true
            this.centerDisabled = false
            this.unitDisabled = false
        }
        if (centerData) {
            this.search.mill_info_id = centerData.mill_info_id
            this.search.subzone_office_id = centerData.subzone_office_id
            this.search.center_id = centerData.value
            this.millDisabled = true
            this.subzoneDisabled = true
            this.centerDisabled = true
            this.unitDisabled = false
        }
        if (unitData) {
            this.search.mill_info_id = unitData.mill_info_id
            this.search.subzone_office_id = unitData.subzone_office_id
            this.search.center_id = unitData.center_id
            this.search.unit_id = unitData.value
        }
    },
    searchData () {
      this.loadData()
    },
    show (item) {
      this.editItemId = item.id
      this.item = item
    },
    details (item) {
      this.item = item
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, subFarApprovalPendingList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList
        const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList
        const subzoneOfficeList = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList
        const centerList = this.$store.state.incentiveGrant.commonObj.centerList
        const unitList = this.$store.state.incentiveGrant.commonObj.unitList
        const listData = data.map(item => {
            const fiscalYearListObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
            const millDataObject = millInfoList.find(mill => mill.value === item.mill_info_id)
            const subzoneObject = subzoneOfficeList.find(zone => zone.value === item.subzone_office_id)
            const centerObject = centerList.find(center => center.value === item.center_id)
            const unitObject = unitList.find(unit => unit.value === item.unit_id)
            const ojbData = {
                fiscal_year: fiscalYearListObject !== undefined ? fiscalYearListObject.text_en : '',
                fiscal_year_bn: fiscalYearListObject !== undefined ? fiscalYearListObject.text_bn : '',
                mill_name: millDataObject !== undefined ? millDataObject.text_en : '',
                mill_name_bn: millDataObject !== undefined ? millDataObject.text_bn : '',
                subzone_name: subzoneObject !== undefined ? subzoneObject.text_en : '',
                subzone_name_bn: subzoneObject !== undefined ? subzoneObject.text_bn : '',
                center_name: centerObject !== undefined ? centerObject.text_en : '',
                center_name_bn: centerObject !== undefined ? centerObject.text_bn : '',
                unit_no: unitObject !== undefined ? unitObject.text_en : '',
                unit_no_bn: unitObject !== undefined ? unitObject.text_en : ''
            }
            return Object.assign({}, item, ojbData)
        })
        return listData
    },
    getMillInfoList (id) {
        const objectData = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1 && item.mill_type_id === id)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getSubzoneList (id) {
        const objectData = this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.filter(item => item.status === 1 && item.mill_info_id === id)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getCenterList (id) {
        const objectData = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.status === 1 && item.subzone_office_id === id)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getUnitList (id) {
        const objectData = this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1 && item.center_id === id)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    }
  }
}
</script>
